@import "../imports/own_frontend_settings/breakpoint";

.sp_titleBarBreadcrumbs, .sp_titleBarTitleWhiteBackground, .sp_titleBarTitleOffset {
  background-color: white;
}

.sp_titleBarBreadcrumbs {
  padding-top: 8px;
  padding-left: 16px;
  padding-bottom: 8px;
}

.sp_breadcrumbItem {
  @media not (min-width: $breakpoint-l) {
    display: none;
  }

  &:after {
    content: " |";
  }

  &:last-of-type:after {
    content: normal;
  }
}

.sp_breadcrumbEllipsisItem {
  @media(min-width: $breakpoint-l) {
    display: none;
  }
}

.sp_breadcrumbItem.alwaysVisible {
  display: inline-block;
}

.sp_titleBarTitle {
  padding-left: 16px;
  padding-bottom: 8px;
  margin-top: -4px;
}

.sp_titleBarTitleOffsetGridContainer {
  display: flex;

  .sp_titleBarTitleOffset {
    flex-grow: 1;
  }
}

.pl_grid-lane>.pl_grid-container>.sp_titleBarTitleOffsetGridContainer {
  padding-top: 0;
}

@media not (min-width: $breakpoint-l) {
  .sp_titleBarTitleOffsetGridContainer {
    display: none;
  }
}
