@import "@otto-ec/global-frontend-settings/all";
@import "../imports/own_frontend_settings/grid";
@import "../imports/own_frontend_settings/breakpoint";

.sp_contentNavigationContainer {
  @media not (min-width: $breakpoint-l) {
    &.sp_leafNode,
    &.sp_noChildren {
      display: none;
    }
  }
}

.sp_contentNavigation {
  background-color: white;
  height: 100%;
}

.sp_contentNavigationItemContent {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 12px;
}

.sp_contentNavigationItemActive {
  font-weight: 700;
}

.sp_contentNavigationItemIcon {
  flex: 0 0 24px;
  height: 24px;

  @media (min-width: $breakpoint-l) {
    display: none;
  }
}
